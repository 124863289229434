import ClassAssistantAssignStudent from "views/pages/ClassAssistant/ClassAssistantAssignStudent";
import ClassAssistantReport from "views/pages/ClassAssistant/ClassAssistantReport";
import ClassAssistantStudentPayments from "views/pages/ClassAssistant/ClassAssistantStudentPayments";
import ClassAssistantStudentPayType from "views/pages/ClassAssistant/ClassAssistantStudentPayType";
import ClassAssistantTeacherPayments from "views/pages/ClassAssistant/ClassAssistantTeacherPayments";
import ClassAssistantStudent from "views/pages/ClassAssistant/ClassAssistnatStudent";
import Recepts from "views/pages/ClassAssistant/Recepts";

var classAssistantRoutes = [
    {
        path: "/student",
        name: "Student",
        icon: "ni fa fa-users text-primary",
        component: <ClassAssistantStudent />,
        layout: "/classassistant",
    },
    {
        path: "/payments",
        name: "Payments",
        icon: "ni fa-regular fa-credit-card text-primary",
        component: <ClassAssistantStudentPayments />,
        layout: "/classassistant",
    },
    {
        path: "/teacher-payments",
        name: "Receipts",
        icon: "ni fa-solid fa-file-invoice text-primary",
        component: <ClassAssistantTeacherPayments />,
        layout: "/classassistant",
    },
    {
        path: "/student-fee",
        name: "Free Card",
        icon: "ni fa fa-money-bill text-primary",
        component: <ClassAssistantStudentPayType />,
        layout: "/classassistant",
    },
    {
        path: "/student-assign",
        name: "Assign",
        icon: "ni fa fa-user text-primary",
        component: <ClassAssistantAssignStudent />,
        layout: "/classassistant",
    },
    // {
    //     path: "/institute-report",
    //     name: "Report",
    //     icon: "ni fa fa-user text-primary",
    //     component: <ClassAssistantReport />,
    //     layout: "/classassistant",
    // },
    // {
    //     path: "/receipts",
    //     name: "Print",
    //     icon: "ni fa fa-user text-primary",
    //     component: <Recepts />,
    //     layout: "/classassistant",
    // },
];

export default classAssistantRoutes;