import React, { useEffect, useState } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";

import { getUser } from "actions/Auth";
import { useNavigate } from "react-router-dom";
import Loader from "views/pages/Loader";
import classAssistantRoutes from "classAssistantRoutes.js";
import ClassAssistantSideBar from "components/Sidebar/ClassAssistantSideBar";
import "../assets/css/class-assistant-styles.css";

const ClassAssistant = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [userDetails, setUserDetails] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await getUser();
        setUser(user);
        setUserDetails(true);
        setUserLoading(false);
      } catch (error) {
        setUserDetails(true);
        setUserLoading(false);
      }
    };

    fetchData();
  }, []);

  React.useEffect(() => {
    if (userLoading === false) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      mainContent.current.scrollTop = 0;
    }
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/classassistant") {
        if (userLoading === true) {
          <Loader></Loader>;
        } else if (userLoading === false) {
          if (user && userDetails) {
            if (user.roleName === "admin") {
              return navigate("/admin/index");
            } 
            else if(user.roleName === 'high-level-admin') {
              navigate("/high-level-admin/index");
            }
            else if (user.roleName === "teacher") {
                return navigate("/teacher/dashboard");
            } else if (user.roleName === 'card-marker') {
              return (
                <Route
                  path={prop.path}
                  element={prop.component}
                  key={key}
                  exact
                />
              );
            }
          } else if (!user && userDetails) {
            return navigate("/auth/login");
          }
        }
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < classAssistantRoutes.length; i++) {
      if (
        location?.pathname.indexOf(
            classAssistantRoutes[i].layout + classAssistantRoutes[i].path
        ) !== -1
      ) {
        return classAssistantRoutes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      {userLoading ? (
        <Loader></Loader>
      ) : (
        <><ClassAssistantSideBar
            {...props}
            routes={classAssistantRoutes}
            logo={{
              innerLink: "/classassistnat/student",
              imgSrc: require("../assets/img/brand/yathra-logo.png"),
              imgAlt: "...",
            }} /><div className="main-content assistant-main" ref={mainContent}>
              <Routes>
                {getRoutes(classAssistantRoutes)}
                <Route path="*" />
              </Routes>
              <Container fluid>
                <AdminFooter />
              </Container>
            </div></>     
    )}
    </>
  );
};

export default ClassAssistant;
