import React, { Fragment } from 'react'
import { Image, Text, View, Page, Document, StyleSheet, Svg, Line } from '@react-pdf/renderer';

const StudentId = ({ data }) => {

    const styles = StyleSheet.create({
        page: { fontSize: 11, paddingTop: 20, paddingLeft: 40, paddingRight: 40, lineHeight: 1.5, flexDirection: 'column' },

        spaceBetween: { flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', color: "#3E3E3E" },

        titleContainer: { flexDirection: 'row', marginTop: 24 },

        logo: { width: 90 },

        reportTitle: { fontSize: 20, fontWeight: 'bold', textAlign: 'left' },

        addressTitle: { fontSize: 11, fontStyle: 'bold' },

        invoice: { fontWeight: 'bold', fontSize: 20 },

        invoiceNumber: { fontSize: 11, fontWeight: 'bold' },

        address: { fontWeight: 400, fontSize: 10 },

        theader: { marginTop: 20, fontSize: 10, fontStyle: 'bold', paddingTop: 4, paddingLeft: 7, flex: 1, height: 20, backgroundColor: '#AD1D21', borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1, color: 'white' },

        theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

        tbody: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1, borderColor: 'whitesmoke', borderRightWidth: 1, borderBottomWidth: 1 },

        total: { fontSize: 9, paddingTop: 4, paddingLeft: 7, flex: 1.5, borderColor: 'whitesmoke', borderBottomWidth: 1 },

        tbody2: { flex: 2, borderRightWidth: 1, },

        infoRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 8,
        },
        icon: {
            width: 20,
            height: 20,
            marginRight: 10,
        },

        invoiceTitle: { fontSize: 26, fontWeight: '700', textAlign: 'left' },
    });

    const InvoiceTitle = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <Image style={styles.logo} src={data.image_url} />
                <Text style={styles.invoiceTitle}>{data.full_name}</Text>
            </View>
        </View>
    );

    const Address = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Text style={styles.invoice}>Ape Sense </Text>
                    <Text style={styles.invoiceNumber}>JAPANESE LANGUAGE ACADEMY (PVT) LTD </Text>
                </View>
                <View>
                    <Text style={styles.addressTitle}>Invoice Number: {data.invoiceNumber}</Text>
                    <Text style={styles.addressTitle}>Invoice Date: {data.paymentDate}</Text>
                </View>
            </View>
        </View>
    );

    const UserAddress = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View style={{ maxWidth: 200 }}>
                    <Text style={styles.addressTitle}>INVOICE TO: {data.name}</Text>
                    <Text style={styles.address}>
                        Phone: {data.phoneNumber}
                    </Text>                    
                    <Text style={styles.addressTitle}>Reg Number: {data.regNumber}</Text>
                </View>
            </View>
        </View>
    );


    const TableHead = () => (
        <View style={{ width: '100%', flexDirection: 'row', marginTop: 10 }}>
            <View style={[styles.theader, styles.theader2]}>
                <Text >DESCRIPTION</Text>
            </View>
            <View style={styles.theader}>
                <Text>COURSE FEE</Text>
            </View>
            <View style={styles.theader}>
                <Text>PAID AMOUNT</Text>
            </View>
            <View style={styles.theader}>
                <Text>BALANCE</Text>
            </View>
        </View>
    );


    const TableBody = () => (
        <View style={{ width: '100%', flexDirection: 'row', height: '250' }}>
            <View style={[styles.tbody, styles.tbody2]}>
                <Text >Payment {data.description} ({data.method})</Text>
            </View>
            <View style={styles.tbody}>
                <Text>{data.courseFee} </Text>
            </View>
            <View style={styles.tbody}>
                <Text>{data.amount}</Text>
            </View>
            <View style={styles.tbody}>
                <Text>{data.balance}</Text>
            </View>
        </View>
    );

    const FooterLine = () => (
        <Svg height="10" width="515">
        <Line
            x1="0"
            y1="0"
            x2="515"
            y2="0"
            strokeWidth={2}
            stroke="#AD1D21"
        />
    </Svg>
    );

    const FooterSection1 = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Text style={{ fontSize: "12px", fontWeight: "600" }}>TERMS AND CONDITIONS</Text>
                    <Text style={{ fontSize: "10px" }}>Please settle bill within 14 days of receiving this invoice.</Text>
                    <Text style={{ fontSize: "12px", fontWeight: "600", marginTop: "10px" }}>THANK YOU FOR JOIN WITH US </Text>
                </View>
                <View style={{ alignItems: 'flex-end', fontWeight: '600' }}>
                    <Text style={styles.addressTitle}>Sub-Total: {data.totalPayment}</Text>
                    <Text style={styles.addressTitle}>Discount: {data.discount}</Text>
                </View>
            </View>
        </View>
    );

    const FooterSection2 = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    {/* Phone Section */}
                    <View style={styles.infoRow}>
                        <Text style={styles.addressTitle}>0725511611</Text>
                    </View>
                    {/* Email Section */}
                    <View style={styles.infoRow}>
                        <Text style={styles.addressTitle}>apesenseacademy@gmail.com</Text>
                    </View>
                    {/* Website Section */}
                    <View style={styles.infoRow}>
                        <Text style={styles.addressTitle}>www.apesense.lk</Text>
                    </View>
                </View>
            </View>
        </View>

    );

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <InvoiceTitle />
                <Address />
                <UserAddress />
                <TableHead />
                <TableBody />              
                <FooterLine/>
                <FooterSection1 />  
                <FooterSection2 />
            </Page>
        </Document>

    )
}

export default StudentId