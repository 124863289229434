import { useState, useEffect } from "react";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
  FormGroup,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  chartOptions,
  parseOptions,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import { getGraphData } from "actions/Dashboard";
import { getInstituteIncomeByMonth } from "actions/Dashboard";
import { getAllTransactions } from "actions/Transactions";
import { getDailyExpenceSum } from "actions/Transactions";
import Paginations from "components/Pagination/Paginations";
import DatePicker from "react-datepicker";
import { findAllClassFeesBalance } from "actions/Transactions";

const Index = () => {
  const [loadingData, setLoadingData] = useState(true);
  const [barGraphData, setBarGraphData] = useState(null);
  const [incomeGraphData, setIncomeGraphData] = useState(null);
  const [allTransactions, setAllTransactiona] = useState(null);
  const [isAllTransactions, setIsAllTransactions] = useState(false);
  const [isLoadingTransactions, setLoadingTransactions] = useState(true);
  const [monthDropdownOpen, setMonthDropdownOpen] = useState(false);
  const [months, setMonths] = useState([]);
  const [balance, setBalance] = useState(null);
  const [classFeesBalance, setClassFeesBalance] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPge, SetItemsPerPage] = useState(5);
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentStart, setCurrentStart] = useState(0);
  const [isSuccess, setSuccess] = useState(false);
  const [isFailed, setFailed] = useState(false);
  const [isErrorMessage, setErrorMessage] = useState("");
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const newMonth = year + "-" + month;
  const newDate = year + "-" + month + "-" + day;
  const [selectedMonth, setSelectedMonth] = useState(newMonth);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isMonth, setMonth] = useState(true);
  const [isDate, setDate] = useState(false);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  const getTransactionDetails = async (date, limit) => {
    try {
      const response = await getAllTransactions(date, limit, itemsPerPge);
      if (response.data.length > 0) {
        setAllTransactiona(response.data);
        setPageCount(response.pageCount);
        setIsAllTransactions(true);
        setLoadingTransactions(false);
      } else {
        setPageCount(0);
        setIsAllTransactions(false);
        setLoadingTransactions(false);
      }
    } catch (error) {
      setIsAllTransactions(false);
      setLoadingTransactions(false);
    }
  }

  const getTransactionBalance = async (date) => {
    try {
      const response = await getDailyExpenceSum(date);
      if (response.balance !== null) {
        setBalance(response.balance);
      } else {
        setBalance(null);
      }
    } catch (error) {
      setFailed(true);
      setErrorMessage(error.response.message);
    }
  }

  const getClassFeesBalance = async (date) => {
    try {
      const response = await findAllClassFeesBalance(date);
      if (response.balance !== null) {
        setClassFeesBalance(response.balance);
      } else {
        setClassFeesBalance(null);
      }
    } catch (error) {
      setFailed(true);
      setErrorMessage(error.response.message);
    }
  }

  const getMonthList = async () => {
    const startDate = new Date();
    const endDate = new Date();
    startDate.setMonth(startDate.getMonth() - (12 - 1));
    const startMonth = startDate.getMonth();
    const endMonth = endDate.getMonth();
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const months = [];

    for (let year = startYear; year <= endYear; year++) {
      const monthStart = year === startYear ? startMonth : 0;
      const monthEnd = year === endYear ? endMonth : 11;

      for (let month = monthStart; month <= monthEnd; month++) {
        const monthString = `${year}-${(month + 1)
          .toString()
          .padStart(2, "0")}`;
        months.push({
          label: monthString,
          month: month + 1,
          year: year,
        });
      }
    }
    setMonths(months);
  }

  const fetchData = async () => {
    try {
      const startDate = new Date();
      const year = startDate.getFullYear();
      const instituteIncomeGraphData = await getInstituteIncomeByMonth(year);
      if (instituteIncomeGraphData.length <= 1) {
        let currentMonth = instituteIncomeGraphData.length === 1 ? instituteIncomeGraphData[0].month : new Date().toISOString().slice(0, 7);
        let date = new Date(currentMonth + "-01");
        date.setMonth(date.getMonth() - 1);
        let previousMonth = date.toISOString().slice(0, 7);
        instituteIncomeGraphData.unshift({ monthly_institute_income: "0", month: previousMonth });
      }
      setIncomeGraphData(instituteIncomeGraphData)
      const barGraphData = await getGraphData(5, 0);
      setBarGraphData(barGraphData);
      await getMonthList();
      const transactions = await getTransactionDetails(selectedMonth, skip);
      await getTransactionBalance(selectedMonth);
      await getClassFeesBalance(selectedMonth);
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!Array.isArray(incomeGraphData)) {
    return <Container fluid>
      <Row style={{ alignItems: "center", justifyContent: "center" }}>
        <Spinner className="m-10">Loading...</Spinner>
      </Row>
    </Container>;
  }

  const dataValues = incomeGraphData?.map(item => parseInt(item.monthly_institute_income, 10));

  const labels = incomeGraphData?.map(item => {
    return (item.month);
  });

  const lineGraphData = {
    labels: labels,
    datasets: [{
      label: 'Income',
      data: dataValues,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };

  const barDataValues = barGraphData?.map(item => parseInt(item.count, 10));

  const barLabels = barGraphData?.map(item => {
    return (item.month);
  });

  const numericBarDataValues = barDataValues?.map(value => parseInt(value, 10));

  const barData = {
    labels: barLabels,
    datasets: [{
      label: 'Count',
      data: numericBarDataValues,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };

  const filterBySearch = async (year, month, label) => {
    setMonth(true);
    setDate(false);
    setSelectedDate(null);
    setSelectedMonth(label);
    await getTransactionDetails(label, skip);
    setCurrentPage(skip + 1);
    await getTransactionBalance(label);
    await getClassFeesBalance(label);
  }

  const filterByDate = async (date) => {
    setDate(true);
    setMonth(false);
    setSelectedMonth(null);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const newDate = year + "-" + month + "-" + day;
    setSelectedDate(newDate);
    await getTransactionDetails(newDate, skip);
    await getTransactionBalance(newDate);
    await getClassFeesBalance(newDate);
    setCurrentStart(0);
    setCurrentPage(skip + 1);
  }
  const handlePagination = async (pageNumber) => {
    if (isMonth) {
      await getTransactionDetails(selectedMonth, pageNumber - 1);
    } else {
      await getTransactionDetails(selectedDate, pageNumber - 1);
    }
    setCurrentPage(pageNumber);
  }

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="text-white mb-0">Institute Income</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Line
                    data={lineGraphData}
                    options={{
                      responsive: true,
                      scales: {
                        yAxes: [{
                          ticks: {
                            beginAtZero: true,
                            min: 0,
                            stepSize: 20000,
                          }
                        }]
                      }
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h2 className="mb-0">Paid Student Count</h2>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={barData}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card className="mt-4 shadow" style={{ padding: "1rem" }}>
          <CardHeader className="border-0">
            <Row>
              <Col>
                <h3 className="mb-0">Transactions</h3>
              </Col>
              <div className="justify-content-end" md="4" lg="4">
                <FormGroup className="mb-2">
                  <Dropdown
                    isOpen={monthDropdownOpen}
                    toggle={() =>
                      setMonthDropdownOpen(!monthDropdownOpen)
                    }
                    className="filterButton"
                    style={{ width: "232px !important" }}
                  >
                    <DropdownToggle caret>
                      {selectedMonth ? selectedMonth : "Filter by Month"}
                    </DropdownToggle>
                    {months && months.length > 0 ? (
                      <DropdownMenu>
                        {months.map((month) => (
                          <DropdownItem
                            key={month.label}
                            value={month.label}
                            onClick={() =>
                              filterBySearch(
                                month.month,
                                month.year,
                                month.label
                              )
                            }
                          >
                            {month.label}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    ) : null}
                  </Dropdown>
                </FormGroup>
                <DatePicker
                  showIcon
                  selected={selectedDate}
                  onChange={(date) => filterByDate(date)}
                  className="dateSelector"
                  icon="fa fa-calendar"
                  placeholderText="Filter by Date"
                />
              </div>
            </Row>
          </CardHeader>
          <Table className="align-items-center" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Description</th>
                <th scope="col" style={{ textAlign: "center" }}>Created By</th>
                <th scope="col" style={{ textAlign: "right" }}>Amount (Rs.)</th>
              </tr>
            </thead>
            {!isAllTransactions ? (
              <tbody>
                <tr style={{ textAlign: "center" }}>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    <img src={require("../assets/img/brand/nodata.png")} className="noDataImage" alt="No Data Available" style={{ margin: "auto" }} />
                  </td>
                </tr>
              </tbody>) :
              <tbody>
                {allTransactions?.map((transaction, index) => (
                  <tr key={index} className={transaction.amount < 0 ? 'creditTransaction' : 'debitTransaction'}>
                    <td>{(transaction.date).split('.')[0]}</td>
                    <td>{transaction.description}</td>
                    <td style={{ textAlign: "center" }}>{transaction.user.full_name}</td>
                    <td style={{ textAlign: "right" }}>{Math.abs(transaction.amount)}</td>
                  </tr>
                ))}
              </tbody>}
            {!isAllTransactions ? null : (
              classFeesBalance !== null ? (
                <tbody>
                  <tr className="debitTransaction classFeesBalanceRow">
                    <td colSpan="3" style={{ textAlign: "right", fontSize: "16px" }}>Class Fees Balance</td>
                    <td style={{ textAlign: "right", fontSize: "16px" }}>{classFeesBalance}</td>
                  </tr>
                </tbody>
              ) : null
            )}

            {balance !== null ? (
              <tbody>
                <tr>
                  <th colSpan="3" style={{ textAlign: "right", fontSize: "20px" }}>Transaction Balance</th>
                  <th style={{ textAlign: "right", fontSize: "20px" }}>{balance}</th>
                </tr>
              </tbody>
            ) : null}
          </Table>
          <div style={{ paddingTop: "10px" }}>
            {pageCount > 1 ?
              <Paginations totalPages={pageCount} handlePagination={handlePagination} currentPage={currentPage}
                currentStart={currentStart}
                setCurrentStart={setCurrentStart}></Paginations>
              : null}
          </div>
        </Card>
        {/* )} */}
      </Container>
    </>
  );
};

export default Index;
