import {
    Card,
    CardHeader,
    Row,
    Col,
    CardBody,
    CardFooter,
} from "reactstrap";
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import "../../assets/css/qr-card-styles.css";
import Barcode from "react-barcode";
import Logo from '../../assets/img/brand/yathra-logo.png';

const QRCard = ({ student }) => {

    return (
        <Card className="qRCard">
            <CardHeader className="cardHeader" style={{ borderBottom: "none" }}>
                <img src={student.image_url} className="stuImageId" width={100} crossOrigin="Access-Control-Allow-Origin" />
                <QRCode value={student.barcode} size={50} className="code" />
            </CardHeader>
            <CardBody className="qrBody">
                <Row>
                    <p className="title">{student.full_name}</p>
                </Row>
                <Row>
                    <p className="regNumber">{student.registration_number}</p>
                </Row>
            </CardBody>
            <div className="mt-2 qrFooter">
                <Barcode className="barCodeId" value={student.barcode} height={40} fontSize={12} />
            </div>
            <CardBody className="pt-0">
                <Row>
                    <div className="instituteDetails">
                        <div>
                            <p className="instAddress">Yathra Institute</p>
                            <p className="instAddress">Kirinda</p>
                            <p className="instAddress">(071) 174 5037</p>
                        </div>
                        <img src={Logo} className="instLogo" />
                    </div>
                </Row>
                <div className="emailId">
                    <p className="web">
                        <strong>Designed By ardillalabs.com</strong>
                    </p>
                </div>
            </CardBody>
        </Card>
    );
}
export default QRCard;
